import Vue from 'vue'
import Vuex from 'vuex'
import io from 'socket.io-client';

Vue.use(Vuex)

const store = new Vuex.Store({
	state:
	{
		current_time: new Date().getTime() / 1000,
		user: {user_id: null, actions: [], receiving_leads: 0},
		users: [],
		logged_in: false, //(this.getCookie("access_token_cookie") != ""),
		accounts: [],
		lead_statuses: [],
		lead_sources: [],
		insurance_types: [],
		insurers: [],
		groups: [],
		phone_numbers: [],
		roles: [],
		actions: [],
		features: [],
		lead_timer: null,
		loading_count: 0,
		notifications: [],
		canned_responses: [],
		socket: null,
		motd: null,
		config: {}
	},
	getters:
	{
		user: state =>
		{
			return state.user;
		},
		getUser: (state) => (user_id) =>
		{
			return state.users.find(user => user.user_id === user_id)
		},
		users: state =>
		{
			return state.users;
		},
		account: state =>
		{
			return state.accounts.find(account => account.account_id === state.user.active_account_id)
		},
		current_time: state =>
		{
			return state.current_time;
		},
		logged_in: state =>
		{
			return state.logged_in;
		},
		accounts: state =>
		{
			return state.accounts;
		},
		lead_statuses: state =>
		{
			return state.lead_statuses;
		},
		lead_sources: state =>
		{
			return state.lead_sources;
		},
		insurance_types: state =>
		{
			return state.insurance_types;
		},
		insurers: state =>
		{
			return state.insurers;
		},
		groups: state =>
		{
			return state.groups;
		},
		phone_numbers: state =>
		{
			return state.phone_numbers;
		},
		roles: state =>
		{
			return state.roles;
		},
		actions: state =>
		{
			return state.actions;
		},
		features: state =>
		{
			return state.features;
		},
		lead_timer: state =>
		{
			return state.lead_timer;
		},
		loading_count: state =>
		{
			return state.loading_count;
		},
		socket: state =>
		{
			return state.socket;
		},
		notifications: state =>
		{
			return state.notifications;
		},
		quick_replies: state =>
		{
			return state.canned_responses.filter(r => r.quick_reply == 1);
		},
		canned_responses: state =>
		{
			return state.canned_responses.filter(r => r.quick_reply == 0);
		},
		motd: state =>
		{
			return state.motd;
		},
		config: (state) => (config_key) =>
		{
			return state.config[config_key];
		}
	},
	mutations:
	{
		current_time(state)
		{
			state.current_time = new Date().getTime() / 1000;
		},
		user(state, user)
		{
			state.user = user;
		},
		users(state, users)
		{
			state.users = users;
		},
		logged_in(state, logged_in)
		{
			state.logged_in = logged_in;
		},
		accounts(state, accounts)
		{
			state.accounts = accounts;
		},
		lead_statuses(state, lead_statuses)
		{
			state.lead_statuses = lead_statuses;
		},
		lead_sources(state, lead_sources)
		{
			state.lead_sources = lead_sources;
		},
		insurance_types(state, insurance_types)
		{
			state.insurance_types = insurance_types;
		},
		insurers(state, insurers)
		{
			state.insurers = insurers;
		},
		groups(state, groups)
		{
			state.groups = groups;
		},
		phone_numbers(state, phone_numbers)
		{
			state.phone_numbers = phone_numbers;
		},
		roles(state, roles)
		{
			state.roles = roles;
		},
		actions(state, actions)
		{
			state.actions = actions;
		},
		features(state, features)
		{
			state.features = features;
		},
		leadTimer(state, timeout)
		{
			state.lead_timer = timeout;
		},
		clearLeadTimer(state)
		{
			clearTimeout(state.lead_timer);
		},
		startLoading(state)
		{
			state.loading_count++;
		},
		stopLoading(state)
		{
			state.loading_count--;
		},
		resetLoading(state)
		{
			state.loading_count = 0;
		},
		socket(state, socket)
		{
			state.socket = socket;
		},
		notification(state, notification)
		{
			state.notifications.splice(0, 0, notification);
		},
		notifications(state, notifications)
		{
			state.notifications = notifications;
		},
		canned_responses(state, canned_responses)
		{
			state.canned_responses = canned_responses;
		},
		motd(state, motd)
		{
			state.motd = (motd ? motd : null);
		},
		config(state, config)
		{
			state.config = (config ? config : null);
		},
		initialize(state)
		{
			state.socket = io({
				"goliathlms.com": "wss://api.goliathlms.com",
				"goliath.nerivon.cloud": "wss://api.goliath.nerivon.cloud",
				"local.goliath.mitchinsurance.com": "wss://local.goliath.mitchinsurance.com:8888"
			}[window.location.hostname.indexOf("goliathlms.com") == -1 ? window.location.hostname : "goliathlms.com"], {
				autoConnect: false,
				reconnection: true,
				transports: ["websocket"]
			});
		},
		connect(state)
		{
			if(state.socket == null)
			{
				return;
			}

			state.socket.connect();
		},
		disconnect(state)
		{
			if(state.socket == null)
			{
				return;
			}

			if(state.socket.connected)
			{
				state.socket.disconnect();
				state.socket = null;
			}
		},
		startListening(state, payload)
		{
			// Clear all listeners for this event.
			// Clearing just the one we're adding doesn't seem
			// to work properly due to reference differences.
			if(state.socket == null)
			{
				return;
			}

			state.socket.off(payload.event);
			state.socket.on(payload.event, payload.callback);
		},
		stopListening(state, payload)
		{
			if(state.socket == null)
			{
				return;
			}

			state.socket.off(payload.event, payload.callback);
		}
	}
});

export default store;
